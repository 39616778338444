import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const DemoPage = loadable(() => import("../content/demo-page"));
const DemoGrid = loadable(() => import("../content/demo-grid"));

function DemoTemplate({ pageContext }) {
  const pageDemoProps = pageContext.demoProps;

  if (pageContext.id === "grid") {
    return <DemoGrid />;
  }
  if (pageDemoProps.gridDemo) {
    return <DemoGrid props={pageDemoProps} dev={pageContext.dev} />;
  }

  return (
    <DemoPage
      type={pageContext.type || pageContext.id}
      section={pageDemoProps}
      dev={pageContext.dev}
    />
  );
}

DemoTemplate.propTypes = {
  pageContext: PropTypes.shape({
    design: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    demoProps: PropTypes.objectOf(PropTypes.any),
    dev: PropTypes.bool,
  }),
};

DemoTemplate.defaultProps = {
  pageContext: { layout: "design", id: "index", demoProps: {}, dev: false },
};

export default DemoTemplate;
